import useMutation from '../../react-query/useMutation';
import useQuery from '../../react-query/useQuery';
import useQueryClient from '../../react-query/useQueryClient';
import useSupabase from './useSupabase';

type CreateImageReqType = {
  itemId: string;
  boardId: string;
  imageUrl: string;
  is_checked?: boolean;
};

type UpdateImageReqType = {
  id: string;
  itemId?: string;
  boardId?: string;
  imageUrl?: string;
  is_checked?: boolean;
};

export type ImageResType = {
  id: string;
  itemId: string;
  boardId: string;
  imageUrl: string;
  created_at: string;
  is_checked: boolean;
};

export type MissionResType = {
  id: string;
  title: string;
  description: string;
  imageUrl: string;
  created_at: string;
  itemId: string;
  boardId: string;
  is_checked: boolean;
  item: {
    id: string;
    name: string;
    type: 'mission';
    created_at: string;
  };
  bingo: {
    id: string;
    name: string;
    board: string;
    created_at: string;
  };
};

export function useCreateImage() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: CreateImageReqType) => await supabase.from('bingoImage').insert(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['image']);
      },
    },
  );
}

export function useMission() {
  const supabase = useSupabase();
  return useQuery<MissionResType[]>(
    ['mission'],
    async () =>
      await supabase
        .from('bingoImage')
        .select('*, item!inner(*), bingo!inner(*)')
        .eq('item.type', 'mission')
        .then((data) => data.data as MissionResType[]),
  );
}

export function useMissionById(id: string) {
  const supabase = useSupabase();
  return useQuery<MissionResType[]>(
    ['mission', id],
    async () =>
      await supabase
        .from('bingoImage')
        .select('*, item!inner(*)')
        .eq('id', id)
        .eq('item.type', 'mission')
        .then((data) => data.data as MissionResType[]),
  );
}

export function useImageById(id: string) {
  const supabase = useSupabase();
  return useQuery<ImageResType[]>(
    ['image', id],
    async () =>
      await supabase
        .from('bingoImage')
        .select()
        .eq('boardId', id)
        .then((data) => data.data as ImageResType[]),
  );
}

export function useUpdateImage() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: UpdateImageReqType) =>
      await supabase.from('bingoImage').update(body).eq('id', body.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['image']);
      },
    },
  );
}
