import useMutation from '../../react-query/useMutation';
import useQuery from '../../react-query/useQuery';
import useQueryClient from '../../react-query/useQueryClient';
import useSupabase from './useSupabase';

type DeleteBingoReqType = {
  id: string;
};

type CreateBingoReqType = {
  name: string;
  board?: string;
};

type UpdateBingoReqType = {
  id: string;
  name: string;
  board?: string;
};

type BingoResType = {
  id: string;
  name: string;
  board: string;
  created_at: string;
};

type ChangeBingoBoardReqType = {
  firstId: string;
  secondId: string;
};

type ChangeBingoBoardPositionReqType = {
  firstTargetPosition: number;
  secondTargetPosition: number;
};

export function useBingo() {
  const supabase = useSupabase();
  return useQuery<BingoResType[]>(
    ['bingo'],
    async () =>
      await supabase
        .from('bingo')
        .select()
        .then((data) => data.data as BingoResType[]),
  );
}

export function useBingoById(id: string) {
  const supabase = useSupabase();
  return useQuery<BingoResType>(
    ['bingo', id],
    async () =>
      await supabase
        .from('bingo')
        .select()
        .eq('id', id)
        .then((data) => data.data?.[0] as BingoResType),
  );
}

export function useCreateBingo() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: CreateBingoReqType) => await supabase.from('bingo').insert(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingo']);
      },
    },
  );
}

export function useDeleteBingo() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: DeleteBingoReqType) => await supabase.from('bingo').delete().eq('id', body.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingo']);
      },
    },
  );
}

export function useUpdateBingo() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, ...body }: UpdateBingoReqType) =>
      await supabase.from('bingo').update(body).eq('id', id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingo']);
      },
    },
  );
}

export function useUpdateBingoBoard() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async ({ id, board }: { id: string; board: string }) =>
      await supabase.from('bingo').update({ board }).eq('id', id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingo']);
      },
    },
  );
}

export function useChangeBingoBoard() {
  const supabase = useSupabase();
  const bingo = useBingo();

  const queryClient = useQueryClient();

  return useMutation(
    async ({ firstId, secondId }: ChangeBingoBoardReqType) => {
      const firstBoard = bingo.data?.find((board) => board.id === firstId);
      const secondBoard = bingo.data?.find((board) => board.id === secondId);
      return await Promise.all([
        supabase.from('bingo').update({ board: secondBoard?.board }).eq('id', firstId),
        supabase.from('bingo').update({ board: firstBoard?.board }).eq('id', secondId),
      ]);
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingo']);
      },
    },
  );
}

export function useChangePositionBingoBoard() {
  const supabase = useSupabase();
  const bingoBoard = useBingo();

  const queryClient = useQueryClient();

  return useMutation(
    async ({
      firstTargetPosition: firstTargetPostion,
      secondTargetPosition,
    }: ChangeBingoBoardPositionReqType) => {
      return await supabase.from('bingo').upsert(
        bingoBoard.data?.map((board) => {
          const boardArray = JSON.parse(board.board) as string[];
          let temp = boardArray[firstTargetPostion];
          if (firstTargetPostion !== -1) {
            boardArray[firstTargetPostion] = boardArray[secondTargetPosition];
          }

          if (secondTargetPosition !== -1) {
            boardArray[secondTargetPosition] = temp;
          }

          return {
            id: board.id,
            board: JSON.stringify(boardArray),
          };
        }),
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingo']);
      },
    },
  );
}
