import useMutation from '../../react-query/useMutation';
import useSupabase from './useSupabase';

export function useUploadImage() {
  const supabase = useSupabase();

  return useMutation(
    async ({ file, path }: { file: File; path: string }) =>
      await supabase.storage.from('image').upload(`${path}.png`, file, {
        cacheControl: '3600',
        upsert: false,
      }),
    {
      onError: (error) => {
        console.error('Error uploading image:', error);
        alert('업로드에 실패했습니다.');
      },
    },
  );
}
