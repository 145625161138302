import { type RouteObject } from 'react-router-dom';

import App from './App';

export const router: RouteObject[] = [
  {
    path: '/:id',
    element: <App />,
    errorElement: <App.Error />,
  },
];
