import QueryClient from '@bingo/react-query/QueryClient';
import QueryClientProvider from '@bingo/react-query/QueryClientProvider';
import '@bingo/tailwind-config/css';
import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

import './index.css';
import { router } from './page/router';

const queryClient = new QueryClient({ defaultOptions: { queries: { staleTime: Infinity } } });
ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <QueryClientProvider queryClient={queryClient}>
      <Suspense>
        <RouterProvider router={createBrowserRouter(router)} />
      </Suspense>
    </QueryClientProvider>
  </React.StrictMode>,
);
