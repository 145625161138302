/* eslint-disable no-shadow */

/* eslint-disable react-hooks/rules-of-hooks */
import { useBingoById } from '@bingo/react-supabase/hook/useBingo';
import { type BingoItemResType, useBingoItem } from '@bingo/react-supabase/hook/useBingoitem';
import {
  type ImageResType,
  useCreateImage,
  useImageById,
} from '@bingo/react-supabase/hook/useImage';
import useSupabase from '@bingo/react-supabase/hook/useSupabase';
import { useUploadImage } from '@bingo/react-supabase/hook/useUploadImage';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

function App() {
  function setScreenSize() {
    const vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  }

  useEffect(() => {
    setScreenSize();
  }, []);

  const { id } = useParams();
  if (!id) return null;
  const bingo = useBingoById(id);
  const bingoItems = useBingoItem();
  const bingoImages = useImageById(id);

  const supabase = useSupabase();
  const uploadImageHook = useUploadImage();
  const createImageHook = useCreateImage();

  const bingoItemsMap = (bingoItems.data ?? []).reduce(
    (acc, cur) => {
      acc[cur.id] = cur;
      return acc;
    },
    {} as Record<string, BingoItemResType>,
  );

  const bingoImagesMap = (bingoImages.data ?? []).reduce(
    (acc, cur) => {
      acc[cur.itemId] = cur;
      return acc;
    },
    {} as Record<string, ImageResType>,
  );

  const bingoBoard = JSON.parse(bingo.data?.board ?? '[]') as string[];

  const getBingoItemStatus = (bingoItemId: string) => {
    const isAcitivityClear =
      bingoImagesMap[bingoItemId] && bingoItemsMap[bingoItemId]?.type === 'activity';
    const isMissionCheck =
      bingoImagesMap[bingoItemId] &&
      bingoItemsMap[bingoItemId]?.type === 'mission' &&
      bingoImagesMap[bingoItemId].is_checked === false;
    const isMissionClear =
      bingoImagesMap[bingoItemId] &&
      bingoItemsMap[bingoItemId]?.type === 'mission' &&
      bingoImagesMap[bingoItemId].is_checked === true;

    return {
      isAcitivityClear,
      isMissionCheck,
      isMissionClear,
    };
  };

  return (
    <div className='h-dvh w-full bg-[url("https://kakao.agit.in/assets/errors/planets-1f461d7284f092339a9c325d411667592e30cd5b8261bae5f025c79d5235f236.png")] bg-contain bg-center bg-no-repeat py-8'>
      <h1 className='whitespace-pre py-8 text-center text-2xl font-bold text-white'>{`24년도 로컬FE\n플레이샵 빙고`}</h1>
      <h2 className='px-4 text-xl font-semibold text-white'>{bingo.data?.name}</h2>
      <div className='grid h-[100vw] w-[100vw] grid-cols-4 grid-rows-4 gap-1 p-4'>
        {bingoBoard.map((bingoItemId) => {
          const { isAcitivityClear, isMissionCheck, isMissionClear } =
            getBingoItemStatus(bingoItemId);

          return bingoItemId !== '' ? (
            <label
              htmlFor={`${bingoItemId}-upload`}
              key={bingoItemId}
              className={`relative flex cursor-pointer items-center justify-center whitespace-break-spaces break-words break-keep text-center text-[3.4vw] font-semibold text-[#262456] ${isAcitivityClear || isMissionClear || isMissionCheck ? ' bg-gray-400' : 'bg-white'}`}
            >
              {(isAcitivityClear || isMissionClear) && (
                <p className='absolute z-10 -rotate-12 text-[5vw] font-extrabold text-red-600'>
                  CLEAR
                </p>
              )}
              {isMissionCheck && (
                <p className='absolute z-10 -rotate-12 text-[5vw] font-extrabold text-white'>
                  체크중
                </p>
              )}
              <p>{bingoItemsMap[bingoItemId]?.name}</p>
              <input
                type='file'
                id={`${bingoItemId}-upload`}
                accept={[
                  'image/jpg',
                  'image/jpeg',
                  'image/png',
                  'image/gif',
                  'image/bmp',
                  '.heic',
                ].join(',')}
                multiple={false}
                placeholder='파일 첨부'
                className='hidden'
                onChange={(e) => {
                  if (!e.target.files || e.target.files?.length === 0) {
                    return;
                  }
                  const file = e.target.files[0];
                  if (file.size > 0) {
                    uploadImageHook.mutate(
                      { file, path: `public/${id}/${new Date().getTime()}` },
                      {
                        onSuccess: async (data: any) => {
                          const res = await supabase.storage.from('Image').getPublicUrl(data.path);
                          createImageHook.mutate({
                            itemId: bingoItemId,
                            boardId: id,
                            imageUrl: res.data.publicUrl,
                          });
                        },
                      },
                    );
                  }
                }}
              />
            </label>
          ) : (
            <p
              className='relative flex items-center justify-center whitespace-nowrap border-2 border-solid border-white bg-white text-center text-[3.4vw] font-semibold text-[#262456]'
              key={bingoItemId}
            >
              보너스
            </p>
          );
        })}
      </div>
      <div className='flex flex-col gap-4 py-4'>
        <h2 className='px-4 text-2xl font-semibold text-white'>미션 설명</h2>
        <div className='flex flex-col gap-4 p-3'>
          {bingoItems.data
            ?.filter(({ type }) => type === 'mission')
            .filter(({ id }) => bingoBoard.includes(id.toString()))
            .map((item) => (
              <div key={item.id}>
                <p className='px-4 text-xl font-semibold text-white'>{item.name}</p>
                <p className='text-md break-words break-keep px-4 pl-6 font-semibold text-[#efeeba]'>
                  {item.description}
                </p>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
}

App.Error = () => (
  <div className='flex h-dvh w-full flex-col items-center justify-center bg-[url("https://kakao.agit.in/assets/errors/planets-1f461d7284f092339a9c325d411667592e30cd5b8261bae5f025c79d5235f236.png")] bg-contain bg-center bg-no-repeat py-8'>
    <p className='text-2xl font-bold text-white'>빙고ID가 잘못 입력되었어요.</p>
    <p className='text-lg font-semibold text-white'>(path에 올바른 빙고ID를 입력해주세요.)</p>
  </div>
);

export default App;
