import useMutation from '../../react-query/useMutation';
import useQuery from '../../react-query/useQuery';
import useQueryClient from '../../react-query/useQueryClient';
import useSupabase from './useSupabase';

type CreateBingoItemReqType = {
  name: string;
  type: 'activity' | 'mission';
  description?: string;
};

type DeleteBingoItemReqType = {
  id: number;
};

type UpdateBingoItemReqType = {
  id: number;
  name: string;
  type: 'activity' | 'mission';
  description?: string;
};

export type BingoItemResType = {
  id: number;
  name: string;
  created_at: string;
  type: 'activity' | 'mission';
  description: string;
};

export function useBingoItem() {
  const supabase = useSupabase();
  return useQuery<BingoItemResType[]>(['bingoItem'], async () => {
    return supabase
      .from('item')
      .select()
      .then((data) => data.data as BingoItemResType[]);
  });
}

export function useCreateBingoItem() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: CreateBingoItemReqType) => await supabase.from('item').insert(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingoItem']);
      },
    },
  );
}

export function useDeleteBingoItem() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: DeleteBingoItemReqType) => await supabase.from('item').delete().eq('id', body.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingoItem']);
      },
    },
  );
}

export function useUpdateBingoItem() {
  const supabase = useSupabase();
  const queryClient = useQueryClient();

  return useMutation(
    async (body: UpdateBingoItemReqType) =>
      await supabase.from('item').update(body).eq('id', body.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['bingoItem']);
      },
    },
  );
}
